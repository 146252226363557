.progress-tracker {
  margin-bottom: 7rem;

  &__header {
    @include font-size(2.5);
    margin-bottom: 3rem !important;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    max-width: 72rem;
  }

  &__link {
    @include font-size(2.6);
    align-items: center;
    background-color: rgba(colour(blue), 0.5);
    color: white;
    display: flex;
    font-weight: bold;
    height: 6.5rem;
    justify-content: center;
    position: relative;
    text-decoration: none;
    width: 6.5rem;
    z-index: 2;

    &.js-active {
      background-color: colour(green);
    }

    &:after {
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 50%,
        rgba(colour(blue), 0.5) 0%
      );
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: 2.5rem 0.5rem;
      content: '';
      display: none;
      height: 0.5rem;
      left: 7.3rem;
      pointer-events: none;
      position: absolute;
      width: 14.8rem;
      z-index: 1;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .progress-tracker {
    &__link {
      &:after {
        display: block;
      }
    }
  }
}
