.page {
  margin: 6rem 0;
  position: relative;

  &__form {
    position: relative;
  }

  &__content {
    display: none;
    left: 0;
    padding-bottom: 7rem;
    position: absolute;
    right: 0;
    top: 0;

    &.js-active {
      display: block;
    }
    &#instructions {
      #instructions-close {
        display: none;    
      }
      &.show-instructions {
        display: block;
        z-index: 1000;
        background-color: white;
        .instructions-next {
          display: none;
        }
        #instructions-close {
          display: inline-block;
        }
      }
    }
  }

  h1 {
    @include font-size(3.5);
    color: colour(blue);
    font-weight: bold;
    margin-bottom: 5rem;
  }

  h2 {
    @include font-size(2.7);
    color: colour(blue);
    font-weight: 400;
    margin-bottom: 5rem;
  }

  h3 {
    @include font-size(2.3);
    font-weight: bold;
    margin-bottom: 3rem;
    text-decoration: underline;
  }

  h4 {
    @include font-size(2.3);
    font-weight: bold;
    margin-bottom: 3rem;
  }

  p {
    @include font-size(1.7);
    line-height: 2.4rem;
  }

  ol {
    margin: 4rem 0;
    padding: 0 0 0 2rem;

    li {
      margin-bottom: 5rem;
    }
  }

  &__image {
    display: block;
    margin-top: 1.5rem;
    max-width: 100%;
  }

  &__required-asterisk {
    color: colour(red);
  }

  &__textarea {
    @include font-size(2);
    border: 0.1rem solid colour(blue);
    display: block;
    margin-bottom: 3rem;
    min-height: 34rem;
    padding: 1.5rem;
    width: 100%;
  }

  &__error-message {
    @include font-size(1.8);
    color: colour(red);
    display: none;
    font-weight: bold;
    margin-top: 3rem;

    &.js-visible {
      display: block;
    }
  }
}
