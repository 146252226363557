.login {
  margin: 10rem 0;
  text-align: center;

  &__header {
    @include font-size(3.5);
    color: colour(blue);
    font-weight: bold;
    margin-bottom: 4.5rem;
  }

  &__form-error {
    color: colour(red);
  }
}
