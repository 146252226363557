.answers {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 2rem -2rem;
  max-width: 35rem;

  &__label {
    position: relative;
  }

  &__radio {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked ~ .answers__styled-radio {
      background-color: colour(blue);
      color: white;
    }
  }

  &__styled-radio {
    @include font-size(2.6);
    align-items: center;
    background-color: rgba(colour(blue), 0.5);
    color: colour(blue);
    cursor: pointer;
    display: flex;
    font-weight: bold;
    height: 6.5rem;
    justify-content: center;
    width: 6.5rem;

    &:hover {
      background-color: rgba(colour(blue), 0.75);
    }
  }
}
