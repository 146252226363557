.button {
  @include font-size(2.8);
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 1.8rem 3rem;
  text-decoration: none;
  transition: background-color 200ms ease, opacity 200ms ease;

  &--default {
    background-color: rgba(colour(blue), 0.7);
    color: white;

    &:hover {
      background-color: rgba(colour(blue), 1);
    }
  }

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}
