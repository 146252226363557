$shades: (
  white: #fff,
  grey: #d6d5d5,
  black: #000
);

$colours: (
  blue: #1475ba,
  green: #14ba33,
  red: #ff0000
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
