.form {
  &__group {
    margin-bottom: 3.7rem;
    position: relative;
  }

  &__input {
    @include font-size(2.7);
    border: 0.1rem solid rgba(colour(blue), 0.5);
    color: colour(blue);
    display: block;
    margin: 0 auto;
    max-width: 54.5rem;
    padding: 1rem 2rem;
    width: 100%;

    &::-webkit-input-placeholder {
      color: rgba(colour(blue), 0.5);
    }

    &::-moz-placeholder {
      color: rgba(colour(blue), 0.5);
    }

    &:-ms-input-placeholder {
      color: rgba(colour(blue), 0.5);
    }

    &:-moz-placeholder {
      color: rgba(colour(blue), 0.5);
    }
  }

  &__error {
    bottom: -2rem;
    color: colour(red);
    left: 0;
    position: absolute;
    right: 0;
  }
}
