* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  @include font-size(1.6);
  font-family: Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 3rem;
  margin-top: 0;
}

p {
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 0;
}

ul li {
  margin-bottom: 0.5rem;
}

a {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

*:focus {
  outline: none;
}

a,
button,
input,
textarea {
  &:focus {
    outline: 0.2rem solid #ffbf47;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
}
